<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="true")
		.widget
			.widget-header
				h1.title Documents
			.widget-body
				form.form.form-inline.ov-filters(@submit.prevent="loadData()")
					.form-row-main
						.form-group
							label Search
							input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword", v-debounce:300ms="loadData")
						.form-group.no-label
							button.btn.btn-primary(type="button", @click="loadData()") Go
					.form-row-end
						.form-group
							label &nbsp;
							button.btn.btn-success(@click="popAddDocument()")
								i.la.la-plus
								| Add

				p.clearfix &nbsp;

				.ov-table-wrapper
					table.table.table-bordered
						thead
							tr
								th ID
								th Type
								th Name
								th Identifier
								th File
								th Actions
						tbody
							tr(v-if="records.length===0")
								td(colspan="6") No matching records were found
							tr(v-for="r in records", v-bind:key="r.id")
								td {{ r.id }}
								td {{ r.type }}
								td.edit-inline
									quick-edit(field="name", :r="r", @on-save="updateField", :wrapped="true")

								td.edit-inline
									quick-edit(field="identifier", :r="r", @on-save="updateField", :wrapped="true")
								td {{ r.created_at }}
								td.actions
									button.btn.btn-sm.btn-secondary(@click="popEditDocument(r)")
										i.la.la-pencil
									| &nbsp;
									a.btn.btn-sm.btn-secondary(href="")
										i.la.la-trash
					paginate(:paginator="paginate", @update-page-size="loadData")

</template>
<script>
//import Vue from "vue";

export default {
  name: 'Documents',
  data() {
    return {
      busy: false,
      filters: { keyword: '' },
      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 50,
        onPageChange: () => {
          this.loadData();
        },
      },
      records: [],
    };
  },
  methods: {
    popAddDocument() {},
    popEditDocument(doc) {
      console.log(doc);
    },
    async loadData() {
      this.busy = true;
      let params = {
        page: this.paginate.page,
        page_size: this.paginate.limit,
        keyword: this.filters.keyword,
      };
      if (this.filters.status === 0) {
        params.status = 0;
      } else if (this.filters.status === 1) {
        params.status = 1;
      }
      try {
        let resp = await this.$ovReq.get('document/getList', { params });
        this.records = resp.records;
        this.paginate.total = resp.total;
        this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
  },
  created() {
    this.loadData();
  },
};
</script>
